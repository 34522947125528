import React from "react";
import { Link } from "gatsby";
import { menuItems } from "./navbar/menuItems";

const FooterLinkSection = ({ label, items }) => (
  <div className="space-y-4">
    <h4 className="text-lg font-bold">{label}</h4>
    <ul className="space-y-2 text-gray-400">
      {items.map((link, index) => (
        <li key={index}>
          <Link to={link.to}>{link.label}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default function Footer() {

  return (
    <>
      <footer className="w-full bg-gray-900 text-white py-12 md:py-16">
        <section className="container mx-auto px-4 md:px-6 py-6 grid grid-cols-2 lg:grid-cols-5 gap-8">
          {menuItems.footer.map((section, index) => (
            <FooterLinkSection key={index} label={section.label} items={section.items} />
          ))}
        </section>
        <div className="container mx-auto px-4 md:px-6 mt-8 text-center text-gray-400">
          © 2025 ICMSA. All rights reserved.
        </div>
      </footer>
    </>
  );
}
