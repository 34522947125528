import React from "react";
import { useLocation } from "@reach/router";
import { Users, ClipboardCheck, MapPin, Telescope } from "lucide-react";
import FeatureCard from "./FeatureCard";
import FWNavBar from "./navbar/FullWidthNavBar";
import { Link } from "gatsby";
import UserForm from '@/components/form/EOI-Form';
import { getPageData } from '../data/pageData';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

const Header = () => {
  const location = useLocation();
  const { backgroundImage, heading, description } = getPageData(location.pathname);
  
  const isHomePage = location.pathname === "/";

  let featureSection;

  const eventName = 'TIAFT 2025'; // Enter the event name (Once set, do not change!)
  const eventDate = '2024-07-03'; // Dynamically set event date in YYYY-MM-DD format

  if (isHomePage) {
    featureSection = (
      <section className="py-12 md:py-20 relative z-10">
        <div className="container mx-auto grid grid-cols-1 gap-6 px-6 sm:grid-cols-2 md:grid-cols-4">
            <FeatureCard
                Icon={Users}
                title="Host & Committee"
                description="Meet the dedicated hosts and committee members of TIAFT 2025."
                link="/host-and-committee/"
            />
            <FeatureCard
                Icon={ClipboardCheck}
                title="Registration"
                description="Register now to secure your spot at TIAFT 2025."
                link="/registration/"
            />
            <FeatureCard
                Icon={MapPin}
                title="Conference Venue"
                description="Discover the prestigious venue for TIAFT 2025."
                link="/conference-venue/"
            />
            <FeatureCard
                Icon={Telescope}
                title="Discover Auckland"
                description="Explore the vibrant city of Auckland during the conference."
                link="/discover-auckland/"
            />
        </div>
    </section>
  );
}

  return (
    <>
      <header
        className="relative text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-70"></div>
        <FWNavBar />
        {/* <InLineNavBar /> */}

        <section className="relative z-10 py-10 lg:py-20">
          <div className="container mx-auto grid grid-cols-1 gap-12 px-6 md:grid-cols-2">
            <div className="space-y-6">
              <Link to="/">
                <img
                  src="/TIAFT2025-logo-horiz-rev.png"
                  alt="TIAFT 2025 Logo"
                  width={300}
                />
              </Link>
              <h1 className="text-4xl font-bold leading-tight text-secondary sm:text-5xl md:text-6xl">
                {heading}
              </h1>
              <p className="text-white text-lg">
                {description}
              </p>
              {isHomePage ? (
              <Dialog>
                <DialogTrigger><button className="confetti-btn__btn min-h-[40px] w-full bg-primary hover:bg-black/90 hover:no-underline text-gray-100 shadow-md text-lg px-4 py-2 rounded-md relative inline-flex justify-center items-center whitespace-nowrap cursor-pointer no-underline leading-tight transition-all duration-200 hover:shadow focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 js-confetti-btn__btn">Subscribe to our News!</button></DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className="mt-0 mb-6">Subscribe to our News!</DialogTitle>
                    <DialogDescription>
                      <UserForm eventName={eventName} eventDate={eventDate} />
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
              ) : (null)}
            </div>
            <div className="relative rounded-lg flex justify-center items-center">
              <div className="">
              {isHomePage ? (
                <>
                <div className="relative flex flex-row border border-white/50 rounded-lg p-8">
                  <div>
                    <h1 className="text-white">TIAFT 2025's venue is moved to the Pullman Auckland Hotel & Apartments!</h1>
                    <Dialog>
                      <DialogTrigger className="mt-4">
                        <button className="confetti-btn__btn min-h-[40px] w-full bg-primary hover:bg-black/90 hover:no-underline text-gray-100 shadow-md text-lg px-4 py-2 rounded-md relative inline-flex justify-center items-center whitespace-nowrap cursor-pointer no-underline leading-tight transition-all duration-200 hover:shadow focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 js-confetti-btn__btn">Read More</button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle className="mt-0">New Venue Announcement!</DialogTitle>
                          <DialogDescription className="space-y-4">
                          <p>We are excited to announce an important change for the upcoming TIAFT 2025 Meeting. Due to the delay in the opening of the New Zealand International Convention Centre (NZICC), we have made the decision to move our TIAFT 2025 to the <b>Pullman Auckland Hotel & Apartments</b>, a beautiful 5-star property just a short distance from the NZICC. The Pullman Auckland has recently undergone a significant refurbishment, offering state-of-the-art facilities, modern hotel rooms, and a fantastic atmosphere for our attendees. Located in the heart of Auckland’s vibrant Central Business District (CBD), the venue provides easy access to the city’s attractions, as well as comfortable onsite accommodations.</p>
                          <p>We are confident that the Pullman Auckland will offer an excellent Meeting experience, and we look forward to welcoming you to this remarkable venue in November 2025! Thank you for your understanding and continued support as we make this exciting transition.</p>
                          <p>If you've already registered or booked accommodation through us, we'll be reaching out to you individually via email.</p>
                          </DialogDescription>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                    
                  </div>
                  <div className="absolute -top-2 -right-2">
                    <span class="relative flex size-5">
                      <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary opacity-75"></span>
                      <span class="relative inline-flex size-5 rounded-full bg-primary"></span>
                    </span>
                  </div>
                </div>
                </>
              ) : (null)}
              </div>
            </div>
          </div>
        </section>

        {featureSection}

      </header>
      
    </>
  );
}

export default Header;

